import React, { useEffect, useRef, useState } from "react";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import useLocalization from "../hooks/useLocalization";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import InfoSection from "../components/terms-of-use/InfoSection";
import "../assets/scss/terms-of-use/index.scss";

const PgTOU = () => {
  const currentLocale = useLocalization() || "us";
  const [isHiddenNavigation, setIsHiddenNavigation] = useState(false);
  const refWhyNotAvailableBlock = useRef(null);

  useEffect(() => {
    setIsHiddenNavigation(
      localStorage.getItem("availability-status") === "true"
    );
  }, []);

  return (
    <Layout bgHeader="#fff" isHiddenNavigation={isHiddenNavigation}>
      <Seo
        title="Terms Of Use | AMZScout"
        description="Please read these Terms carefully. By using amzscout.net (the “Site”), AMZScout’s software or signing up for an account, you’re agreeing to these Terms. This is a legally binding agreement."
        page="terms-of-use"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <InfoSection
        currentLocale={currentLocale}
        refWhyNotAvailableBlock={refWhyNotAvailableBlock}
      />
    </Layout>
  );
};

export default PgTOU;
